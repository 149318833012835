import { authHeader } from './auth-header';
import appConfig from "@/app.config";

export const userService = {
    login,
    logout,
    register,
    getAll,
};

function login(email, password) {

    const body = { 
        email : email, 
        password : password,
     };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return fetch(`${appConfig.api.baseURL}api/login`, requestOptions)
        .then(handleResponse)
        .then(user => {


            //console.log(handleResponse);
            //console.log(user);
            // login successful if there's a jwt token in the response
            /*
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            */

            localStorage.setItem(appConfig.storageUserKey, JSON.stringify(user));
            return user;
            
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const responseData = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                this.$router.push("/login");
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        const data = {
            [appConfig.storageTokenKeyName]: responseData.token,
            [appConfig.storageTokenTypeKeyName]: responseData.tokenType,
            userId : responseData.user.userId,
            name : responseData.user.name,
            familyName : responseData.user.familyName,
            username : responseData.user.username,
            email : responseData.user.email,
            notification : responseData.user.notification,
            branchId : responseData.user.branchId,
            userAccessBranch : responseData.userAccessBranch,
            userAccessProgram : responseData.userAccessProgram,
            userPriority : responseData.user.userPriority
        };
        return data;
    });
}
