import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/pages/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/pages/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
              name: "home",
            }
            : {
              ...routeFrom,
            }
        );
      },
    },
  },
  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/index"),
  },
  {
    path: "/calendar",
    name: "calendar",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/calendar/index"),
  },
  // {
  //   path: '/chat',
  //   name: 'chat',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/chat/index')
  // },
  {
    path: "/ecommerce/products",
    name: "Products",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ecommerce/products"),
  },
  {
    path: "/ecommerce/product-detail/:id",
    name: "Product Detail",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ecommerce/product-detail"),
  },
  {
    path: "/ecommerce/orders",
    name: "Orders",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ecommerce/orders"),
  },
  {
    path: "/ecommerce/customers",
    name: "Customers",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ecommerce/customers"),
  },
  {
    path: "/ecommerce/cart",
    name: "Cart",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ecommerce/cart"),
  },
  {
    path: "/ecommerce/checkout",
    name: "Checkout",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ecommerce/checkout"),
  },
  {
    path: "/ecommerce/shops",
    name: "Shops",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ecommerce/shops"),
  },
  {
    path: "/ecommerce/add-product",
    name: "Add Product",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ecommerce/add-product"),
  },
  {
    path: "/email/inbox",
    name: "Inbox",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/email/inbox"),
  },
  {
    path: "/email/reademail/:id",
    name: "Read Email",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/email/reademail"),
  },
  // {
  //   path: '/invoices/detail',
  //   name: 'Invoice Detail',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/invoices/detail')
  // },
  // {
  //   path: '/invoices/list',
  //   name: 'Invoice List',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/invoices/list')
  // },
  {
    path: "/contacts/grid",
    name: "User Grid",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/contacts/grid"),
  },
  {
    path: "/contacts/list",
    name: "User List",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/contacts/list"),
  },
  {
    path: "/contacts/profile",
    name: "Profile",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/contacts/profile"),
  },
  {
    path: "/utility/starter",
    name: "Starter-page",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/utility/starter"),
  },
  {
    path: "/utility/maintenance",
    name: "maintenance",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/utility/maintenance"),
  },
  {
    path: "/utility/comingsoon",
    name: "comingsoon",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/utility/comingsoon"),
  },
  {
    path: "/utility/timeline",
    name: "timeline",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/utility/timeline"),
  },
  {
    path: "/utility/faqs",
    name: "faqs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/utility/faqs"),
  },
  {
    path: "/utility/pricing",
    name: "pricing",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/utility/pricing"),
  },
  {
    path: "/utility/404",
    name: "error-404",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/utility/404"),
  },
  {
    path: "/utility/500",
    name: "error-500",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/utility/500"),
  },
  {
    path: "/form/elements",
    name: "elements",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/form/elements"),
  },
  {
    path: "/form/validation",
    name: "validation",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/form/validation"),
  },
  {
    path: "/form/advanced",
    name: "form-advanced",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/form/advanced"),
  },
  {
    path: "/form/editor",
    name: "editor",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/form/editor"),
  },
  {
    path: "/form/upload",
    name: "upload",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/form/upload"),
  },
  {
    path: "/form/repeater",
    name: "repeater",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/form/repeater"),
  },
  {
    path: "/form/wizard",
    name: "wizard",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/form/wizard"),
  },
  {
    path: "/form/mask",
    name: "mask",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/form/mask"),
  },
  {
    path: "/tables/basic",
    name: "basic-table",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/tables/basic"),
  },
  {
    path: "/tables/advanced",
    name: "advanced",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/tables/advanced"),
  },
  {
    path: "/charts/apex",
    name: "apex",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/charts/apex/index"),
  },
  {
    path: "/charts/chartist",
    name: "chartist",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/charts/chartist/index"),
  },
  {
    path: "/charts/chartjs",
    name: "chartjs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/charts/chartjs/index"),
  },
  {
    path: "/charts/echart",
    name: "echart",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/charts/echart/index"),
  },
  {
    path: "/icons/unicons",
    name: "unicons",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/icons/unicons"),
  },
  {
    path: "/icons/boxicons",
    name: "boxicons",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/icons/boxicons"),
  },
  {
    path: "/icons/materialdesign",
    name: "materialdesign",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/icons/materialdesign"),
  },
  {
    path: "/icons/dripicons",
    name: "dripicons",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/icons/dripicons"),
  },
  {
    path: "/icons/fontawesome",
    name: "fontawesome",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/icons/fontawesome"),
  },
  {
    path: "/maps/google",
    name: "google",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/maps/google"),
  },
  {
    path: "/maps/leaflet",
    name: "leaflet",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/maps/leaflet"),
  },
  {
    path: "/ui/alerts",
    name: "alerts",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/alerts"),
  },
  {
    path: "/ui/buttons",
    name: "buttons",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/buttons"),
  },
  {
    path: "/ui/cards",
    name: "cards",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/cards"),
  },
  {
    path: "/ui/carousel",
    name: "carousel",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/carousel"),
  },
  {
    path: "/ui/dropdown",
    name: "dropdown",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/dropdown"),
  },
  {
    path: "/ui/grid",
    name: "grid",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/grid"),
  },
  {
    path: "/ui/images",
    name: "images",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/images"),
  },
  {
    path: "/ui/lightbox",
    name: "lightbox",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/lightbox"),
  },
  {
    path: "/ui/modals",
    name: "modals",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/modals"),
  },
  {
    path: "/ui/rangeslider",
    name: "rangeslider",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/rangeslider"),
  },
  {
    path: "/ui/progressbar",
    name: "progressbar",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/progressbar"),
  },
  {
    path: "/ui/sweet-alert",
    name: "sweet-alert",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/sweet-alert"),
  },

  {
    path: "/ui/tabs-accordions",
    name: "tabs-accordions",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/tabs-accordions"),
  },
  {
    path: "/ui/typography",
    name: "typography",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/typography"),
  },

  {
    path: "/ui/video",
    name: "video",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/video"),
  },
  {
    path: "/ui/general",
    name: "general",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/general"),
  },
  {
    path: "/ui/colors",
    name: "colors",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/colors"),
  },
  {
    path: "/ui/rating",
    name: "rating",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ui/rating"),
  },
  {
    path: "/auth/login-1",
    name: "login-1",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/auth/login-1"),
  },
  {
    path: "/auth/register-1",
    name: "register-1",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/auth/register-1"),
  },
  {
    path: "/auth/lock-screen",
    name: "lock-screen",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/auth/lock-screen"),
  },
  {
    path: "/auth/recoverpwd",
    name: "recoverpwd",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/auth/recoverpwd"),
  },
  {
    path: "/customer/formcus",
    name: "formcus",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/form"),
  },
  {
    path: "/spare-part/add-sparepart",
    name: "add-sparepart",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/add-sparepart"),
  },
  {
    path: "/purchase-order/add-po",
    name: "add-po",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/add-po"),
  },
  {
    path: "/parts/add-recieve",
    name: "add-recieve",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/add-recieve"),
  },
  {
    path: "/parts/edit-recieve/:receiptId",
    name: "edit-recieve",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/edit-recieve"),
  },
  {
    path: "/parts/partMonitor",
    name: "partMonitor",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/partMonitor"),
  },
  {
    path: "/appraise",
    name: "appraise",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/mycars/appraise"),
  },
  {
    path: "/addmycars",
    name: "addmycars",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/mycars/addMycar"),
  },
  {
    path: "/detail/:userId",
    name: "detailmycars",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/mycars/detailMycars"),
  },
  {
    path: "/tablemycars",
    name: "tablemycars",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/mycars/tableMycar"),
  },
  {
    path: "/checkmycar",
    name: "checkmycar",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/mycars/checkMycar"),
  },
  {
    path: "/checkmycar/:svId/",
    name: "editCheckMycar",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/mycars/checkMycar"),
  },
  {
    path: "/checkmycar/:svId/:roId",
    name: "editRO",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/mycars/checkMycar"),
  },
  {
    path: "/vehiclelist",
    name: "vehiclelist",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/mycars/vehicleList"),
  },
  {
    path: "/vehicleform",
    name: "vehicleform",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/mycars/vehicleForm"),
  },
  {
    path: "/vehicleformdetail/:vehicleId",
    name: "vehicleformdetail",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/mycars/vehicleFormDetail"),
  },
  {
    path: "/servicelist",
    name: "servicelist",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/mycars/serviceList"),
  },
  {
    path: "/servicedetail/:id",
    name: "servicedetail",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/mycars/serviceDetail"),
  },
  {
    path: "/checkmycardetail/:id",
    name: "checkmycardetail",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/mycars/checkMycar"),
  },
  {
    path: "/serviceGallery",
    name: "servicegallery",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/mycars/serviceGallery"),
  },

  {
    path: "/purchase-order",
    name: "purchase-order",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/purchase-order"),
  },
  {
    path: "/parts/stockPart",
    name: "stockPart",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/stockPart"),
  },
  {
    path: "/parts/recieve-inventory",
    name: "recieve-inventory",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/recieve-inventory"),
  },
  {
    path: "/spare-part",
    name: "spare-part",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/spare-part"),
  },

  {
    path: "/parts/part-picking",
    name: "part-picking",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/partPicking"),
  },
  {
    path: "/parts/add-part-picking",
    name: "add-part-picking",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/addPartPicking"),
  },
  {
    path: "/parts/create-part-picking",
    name: "detail-part-picking",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/detailPartPicking"),
  },
  {
    path: "/parts/detail-part-picking/:pickingId",
    name: "detail-part-pickingId",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/detailPartPicking"),
  },

  {
    path: "/part-requisition",
    name: "part-requisition",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/part-requisition"),
  },
  {
    path: "/part-requisition/add-requisition",
    name: "add-requisition",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/add-requisition"),
  },
  {
    path: "/part-requisition/edit-requisition/:reqId",
    name: "edit-requisition",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/edit-requisition"),
  },
  {
    path: "/customer/maincus",
    name: "maincus",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/maincus"),
  },
  {
    path: "/appointment",
    name: "appointment",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/appointment"),
  },
  {
    path: "/flowslotCRM",
    name: "flowslotCRM",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/flowslotCRM"),
  },
  {
    path: "/appointmentform",
    name: "appointmentform",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/appointMentForm"),
  },
  {
    path: "/fabric",
    name: "fabric",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/mycars/fabric"),
  },
  {
    path: "/appointmentform/:id",
    name: "appointmentformedit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/appointMentFormEdit"),
  },
  {
    path: "/package/mainPackage",
    name: "mainPackage",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/package/mainPackage"),
  },
  {
    path: "/package/formPackage",
    name: "formPackage",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/package/formPackage"),
  },
  {
    path: "/main-user",
    name: "main-user",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/users/main-user"),
  },
  {
    path: "/main-user/add-user",
    name: "add-user",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/users/add-user"),
  },
  {
    path: "/dealer/mainDealer",
    name: "mainDealer",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dealer/mainDealer"),
  },
  {
    path: "/dealer/formDealerBranch",
    name: "formDealerBranch",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dealer/formDealerBranch"),
  },
  {
    path: "/dealer/formDealer",
    name: "formDealer",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dealer/formDealer"),
  },
  {
    path: "/dealer/formDealer/:dealerId",
    name: "formDealer-edit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dealer/formDealer-edit"),
  },
  {
    path: "/dealer/formDealerBranch/:branchId",
    name: "formDealerBranch-edit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dealer/formDealerBranch-edit"),
  },
  {
    path: "/dealer/mainDealerBranch",
    name: "mainDealerBranch",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dealer/mainDealerBranch"),
  },
  {
    path: "/FlowSlot/mainFlowSlot",
    name: "FlowSlot",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/FlowSlot/mainFlowSlot"),
  },

  {
    path: "/statusBoard/mainStatusBoard",
    name: "StatusBoard",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/statusBoard/mainStatusBoard"),
  },
  {
    path: "/statusBoard/mainStatusBoardAds",
    name: "StatusBoardAds",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/statusBoard/mainStatusBoardAds"),
  },
  {
    path: "/statusBoard/welcomeBoard",
    name: "welcomeBoard",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/statusBoard/welcomeBoard"),
  },
  {
    path: "/branch-wages/add-wages",
    name: "add-wages",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/wages/add-wages"),
  },
  {
    path: "/wages/master-wages",
    name: "master-wages",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/wages/master-wages"),
  },
  {
    path: "/main-user/update-user/:userId",
    name: "update-user",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/users/update-user"),
  },
  {
    path: "/customer/formcus/:cusId",
    name: "formcus-edit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/form-edit"),
  },

  {
    path: "/spare-part/update-part/:partId",
    name: "update-part",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages//parts/update-part"),
  },
  {
    path: "/branch-wages",
    name: "branch-wages",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/wages/branch-wages"),
  },
  {
    path: "/branch-wages/update-branchWages/:wageId",
    name: "update-branchWages",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/wages/update-branchWages"),
  },
  {
    path: "/wages/add-masterWages",
    name: "add-masterWages",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/wages/add-masterWages"),
  },
  {
    path: "/wages/update-masterWages/:wageId",
    name: "update-masterWages",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/wages/update-masterWages"),
  },
  {
    path: "/parts/master-part",
    name: "master-part",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/master-part"),
  },
  {
    path: "/parts/add-masterPart",
    name: "add-masterPart",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/add-masterPart"),
  },
  {
    path: "/parts/update-masterPart/:partId",
    name: "update-masterPart",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/update-masterPart"),
  },
  {
    path: "/customer/formPackage/:packageId",
    name: "formEditPackage",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/package/formEditPackage"),
  },
  {
    path: "/contactMessage/contactMessageForm",
    name: "contactMessageForm",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/contactMessage/contactMessageForm"),
  },
  {
    path: "/contactMessage",
    name: "contactMessage",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/contactMessage/contactMessage"),
  },
  {
    path: "/contactMessage/detailContact/:groupContactId",
    name: "detailContact",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/contactMessage/detailContact"),
  },
  {
    path: "/fb-connect",
    name: "fb-connect",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/contactMessage/facebookConnect"),
  },
  {
    path: "/social-contact",
    name: "social-contact",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/contactMessage/contactMessageSocial"),
  },

  {
    path: "/FlowSlot/FlowSlotDRT",
    name: "FlowSlotDRT",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/FlowSlot/FlowSlotDRT"),
  },
  {
    path: "/purchase-order/edit-po/:poId",
    name: "edit-po",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/edit-po"),
  },
  {
    path: "/parts/edit-returnPart/:returnId",
    name: "edit-returnPart",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/edit-returnPart"),
  },

  {
    path: "/tax-invoice",
    name: "taxInvoice",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/receipt/taxInvoice"),
  },
  {
    path: "/tax-invoice/create-taxInvoice",
    name: "create-taxInvoice",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/receipt/detailTaxInvoice"),
  },
  {
    path: "/tax-invoice/detail-tax-invoice/:taxInvId",
    name: "detail-tax-invoiceId",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/receipt/detailTaxInvoice"),
  },

  {
    path: "/sales-tax",
    name: "sales-tax",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/receipt/salesTax"),
  },

  {
    path: "/ro-cancellation",
    name: "ro-cancellation",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/roCancellation/roCancellations"),
  },
  {
    path: "/ro-cancellation/detail-cancellation/:cancelId",
    name: "ro-cancellation-detail",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/roCancellation/detailRoCancellation"),
  },

  {
    path: "/quotations",
    name: "quotations",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/quotation/quotations"),
  },
  {
    path: "/quotations/create-quotation",
    name: "quotation-create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/quotation/detailQuotation"),
  },
  {
    path: "/quotations/detail-quotation/:qnId",
    name: "quotation-detail",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/quotation/detailQuotation"),
  },

  {
    path: "/invoices",
    name: "invoices",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/invoices/inVoice"),
  },
  {
    path: "/invoices/detail-invoice/:invId",
    name: "detail-invoiceId",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/invoices/detailInvoice"),
  },
  {
    path: "/invoices/create-invoice",
    name: "create-invoice",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/invoices/detailInvoice"),
  },

  {
    path: "/payments",
    name: "payments",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/finance/payMents"),
  },
  {
    path: "/payments/create-payment",
    name: "create-payment",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/finance/createPayment"),
  },
  {
    path: "/payments/detail-payment/:paymentId",
    name: "detail-payment",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/finance/detailPayment.vue"),
  },

  {
    path: "/payment-methods",
    name: "payment-methods",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/paymentMethods/paymentMethods"),
  },
  {
    path: "/payment-methods/create-payment-method",
    name: "create-payment-method",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/paymentMethods/createPaymentMethod"),
  },
  {
    path: "/payment-methods/detail-payment-method/:payMethId",
    name: "detail-payment-method",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/paymentMethods/detailPaymentMethod"),
  },

  {
    path: "/extra-service",
    name: "extra-service",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/extraService/extraServices"),
  },
  {
    path: "/extra-service/create-extra-service",
    name: "create-extra-service",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/extraService/createExtraService"),
  },
  {
    path: "/extra-service/detail-extra-service/:extraId",
    name: "detail-extra-service",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/extraService/detailExtraService"),
  },

  {
    path: "/billings",
    name: "billings",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/billing/billings"),
  },
  {
    path: "/billings/detail-billing/:billingId",
    name: "detail-billing",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/billing/detailBilling"),
  },

  {
    path: "/parts/returnPart",
    name: "returnPart",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/returnPart"),
  },
  {
    path: "/parts/add-returnPart",
    name: "add-returnPart",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/add-returnPart"),
  },
  {
    path: "/add-finish",
    name: "addFinishRo",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/receipt/addFinishRo"),
  },

  {
    path: "/import-package",
    name: "import-package",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/importData/importPackage"),
  },

  {
    path: "/vehiclemodels",
    name: "vehiclemodels",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/vehicleBrands/vehicleModelList"),
  },
  {
    path: "/vehiclemodels/vehiclemodelform",
    name: "vehiclemodelform",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/vehicleBrands/vehicleModelForm"),
  },
  {
    path: "/vehiclemodels/vehiclemodelformdetail/:modelId",
    name: "vehiclemodelformdetail",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/vehicleBrands/vehicleModelForm"),
  },
  {
    path: "/report",
    name: "report",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/report/report"),
  },
  {
    path: "/repair-history",
    name: "repairHistory",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/repairHistory"),
  },
  {
    path: "/outsourcing-service",
    name: "outsourcing-service",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/branch-outsourcing-service/outsourcing-service"),
  },
  {
    path: "/form-outsourcing-service",
    name: "form-outsourcing-service",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "../views/pages/branch-outsourcing-service/form-outsourcing-service"
      ),
  },
  {
    path: "/edit-outsourcing-service/:osId",
    name: "edit-outsourcing-service",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "../views/pages/branch-outsourcing-service/edit-outsourcing-service"
      ),
  },
  {
    path: "/main-outsourcing",
    name: "main-outsourcing",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/outsourcing/main-outsourcing"),
  },
  {
    path: "/form-outsourcing",
    name: "form-outsourcing",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/outsourcing/form-outsourcing"),
  },
  {
    path: "/edit-outsourcing/:outsourcingId",
    name: "edit-outsourcing",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/outsourcing/edit-outsourcing"),
  },
  {
    path: "/main-received-outsourcing",
    name: "main-received-outsourcing",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/received-outsourcing/main-received-outsourcing"),
  },
  {
    path: "/form-received-outsourcing",
    name: "form-received-outsourcing",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/received-outsourcing/form-received-outsourcing"),
  },
  {
    path: "/edit-received-outsourcing/:recOsId",
    name: "edit-received-outsourcing",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/received-outsourcing/edit-received-outsourcing"),
  },

  {
    path: "/d-stock-vehicle",
    name: "d-stock-vehicle",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/d-stock-vehicle/dStockVehicle"),
  },

  {
    path: "/diag-car/:svId/:diagId",
    name: "diag-car",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/diagCar/diag-car"),
  },
  {
    path: "/main-diag-car",
    name: "main-diag-car",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/diagCar/main-diag-car"),
  },
  {
    path: "/dealer-financial-institution",
    name: "dealer-financial-institution",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "../views/pages/dealer-financial-institution/main-dealer-financial-institution"
      ),
  },
  {
    path: "/dealer-financial-institution/form",
    name: "dealer-financial-institution/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "../views/pages/dealer-financial-institution/form-dealer-financial-institution"
      ),
  },
  {
    path: "/dealer-financial-institution/edit/:fiId",
    name: "dealer-financial-institution/edit",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "../views/pages/dealer-financial-institution/edit-dealer-financial-institution"
      ),
  },
  {
    path: "/part-lost-sale",
    name: "partLostSale",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/partLostSale"),
  },
  {
    path: "/part-lost-sale/add-PartLostSale",
    name: "add-PartLostSale",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/add-PartLostSale"),
  },
  {
    path: "/part-lost-sale/edit-PartLostSale/:plsId",
    name: "edit-PartLostSale",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/parts/edit-PartLostSale"),
  },
  {
    path: "/product-type",
    name: "product-type",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/product-type/main-product-type"),
  },
  {
    path: "/form-product-type",
    name: "form-product-type",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/product-type/form-product-type"),
  },
  {
    path: "/edit-product-type/:prodTypeId",
    name: "edit-product-type",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/product-type/edit-product-type"),
  },

  {
    path: "/d-activity",
    name: "d-activity",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/d-activity/main-d-activity"),
  },
  {
    path: "/d-activity/form",
    name: "d-activity/form",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/d-activity/form-d-activity"),
  },
  {
    path: "/d-activity/edit/:actId",
    name: "d-activity/edit",
    meta: {
      authRequired: true,
    },

    component: () => import("../views/pages/d-activity/edit-d-activity"),
  },
  {
    path: "/prospect-customer",
    name: "prospect-customer",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/prospectCustomer"),
  },
  {
    path: "/prospect-customer/add-prospectCustomer",
    name: "add-prospectCustomer",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/add-prospectCustomer"),
  },
  {
    path: "/prospect-customer/edit-prospectCustomer:ppCusId",
    name: "edit-prospectCustomer",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/edit-prospectCustomer"),
  },

  {
    path: "/product-sales",
    name: "product-sales",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/products/productSale"),
  },
  {
    path: "/product-sales/detail-productsale/:prodIdBase64",
    name: "detail-product-sale",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/products/detailProductSale"),
  },

  {
    path: "/products",
    name: "products",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/products/products"),
  },
  {
    path: "/products/create-product",
    name: "create-product",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/products/createProduct"),
  },
  {
    path: "/products/detail-product/:prodIdBase64",
    name: "detail-product",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/products/createProduct"),
  },

  {
    path: "/vehicle-po",
    name: "vehicle-po",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/branchVehiclePo/vehiclePo"),
  },
  {
    path: "/vehicle-po/createVehiclePO",
    name: "create-vehicle-po",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/branchVehiclePo/createVehiclePO"),
  },
  {
    path: "/vehicle-po/detailVehiclePO/:poId",
    name: "detail-vehicle-po",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/branchVehiclePo/detailVehiclePO"),
  },

  {
    path: "/received-vehicle",
    name: "received-vehicle",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/received-vehicle/receivedVehicle"),
  },
  {
    path: "/received-vehicle/create-received-vehicle",
    name: "create-received-vehicle",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/received-vehicle/createReceivedVehicle"),
  },
  {
    path: "/received-vehicle/detail-received-vehicle/:rcvdId",
    name: "detail-received-vehicle",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/received-vehicle/detailReceivedVehicle"),
  },

  {
    path: "/vehicle-transfer",
    name: "vehicle-transfer",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/vehicle-transfer/vehicleTransfer"),
  },
  {
    path: "/vehicle-transfer/create-vehicle-transfer",
    name: "create-vehicle-transfer",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/vehicle-transfer/createVehicleTransfer"),
  },
  {
    path: "/vehicle-transfer/detail-vehicle-transfer/:transferId",
    name: "detail-vehicle-transfer",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/vehicle-transfer/detailTransferVehicle"),
  },

  {
    path: "/approve-vehicle-transfer",
    name: "approve-vehicle-transfer",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/approve-vehicle-transfer/approveVehicleTransfer"),
  },
  {
    path: "/approve-vehicle-transfer/create-approve-vehicle-transfer",
    name: "create-approve-vehicle-transfer",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "../views/pages/approve-vehicle-transfer/createApproveVehicleTransfer"
      ),
  },
  {
    path: "/approve-vehicle-transfer/detail-approve-vehicle-transfer/:apvId",
    name: "detail-approve-vehicle-transfer",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "../views/pages/approve-vehicle-transfer/detailApproveVehicleTransfer"
      ),
  },

  {
    path: "/received-vehicle-transfer/create-received-vehicle-transfer",
    name: "create-received-vehicle-transfer",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "../views/pages/received-vehicle-transfer/createReceivedVehicleTransfer"
      ),
  },
  {
    path: "/received-vehicle-transfer",
    name: "received-vehicle-transfer",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "../views/pages/received-vehicle-transfer/receivedVehicleTransfer"
      ),
  },
  {
    path: "/received-vehicle-transfer/detail-received-vehicle-transfer/:rcvdId",
    name: "detail-received-vehicle-transfer",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "../views/pages/received-vehicle-transfer/detailReceivedVehicleTransfer"
      ),
  },

  {
    path: "/stock-vehicle",
    name: "stock-vehicle",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/stockVehicle/stockVehicles"),
  },

  {
    path: "/contact-job",
    name: "contact-job",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/contactJob"),
  },
  {
    path: "/contact-job/contact-calendar",
    name: "contact-calendar",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/contactJobCalendar"),
  },

  {
    path: "/customer-map",
    name: "customer-map",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer-map/customerMap"),
  },

  {
    path: "/credit-inquire",
    name: "credit-inquire",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/credit-inquire/creditInquire"),
  },
  {
    path: "/credit-inquire/create-credit-inquire",
    name: "create-credit-inquire",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/credit-inquire/detailCreditInquire"),
  },
  {
    path: "/credit-inquire/detail-credit-inquire/:inquireId",
    name: "detail-credit-inquire",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/credit-inquire/detailCreditInquire"),
  },

  {
    path: "/approve-credit-inquire",
    name: "approve-credit-inquire",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/approve-credit-inquire/approveCreditInquire"),
  },
  {
    path: "/approve-credit-inquire/detail-approve-credit-inquire/:inquireId",
    name: "detail-approve-credit-inquire",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "../views/pages/approve-credit-inquire/detailApproveCreditInquire"
      ),
  },

  {
    path: "/vehicle-audit",
    name: "vehicle-audit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/vehicle-audit/vehicleAudit"),
  },
  {
    path: "/vehicle-audit/create-vehicle-audit",
    name: "create-vehicle-audit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/vehicle-audit/createVehicleAudit"),
  },
  {
    path: "/vehicle-audit/detail-vehicle-audit/:auditId",
    name: "detail-vehicle-audit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/vehicle-audit/detailVehicleAudit"),
  },

  {
    path: "/d-campaign",
    name: "d-campaign",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/d-campaign/main-d-campaign"),
  },
  {
    path: "/d-campaign/form",
    name: "d-campaign/form",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/d-campaign/form-d-campaign"),
  },
  {
    path: "/d-campaign/edit/:campaignId",
    name: "d-campaign/edit",
    meta: {
      authRequired: true,
    },

    component: () => import("../views/pages/d-campaign/edit-d-campaign"),
  },

  {
    path: "/d-customer-source",
    name: "d-customer-source",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-customer-source/main-d-customer-source"),
  },
  {
    path: "/d-customer-source/form",
    name: "d-customer-source/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-customer-source/form-d-customer-source"),
  },
  {
    path: "/d-customer-source/edit/:cusSrcId",
    name: "d-customer-source/edit",
    meta: {
      authRequired: true,
    },

    component: () =>
      import("../views/pages/d-customer-source/edit-d-customer-source"),
  },

  {
    path: "/d-occupation",
    name: "d-occupation",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/d-occupation/main-d-occupation"),
  },
  {
    path: "/form-d-occupation",
    name: "form-d-occupation",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/d-occupation/form-d-occupation"),
  },
  {
    path: "/edit-d-occupation/:occId",
    name: "edit-d-occupation",
    meta: {
      authRequired: true,
    },

    component: () => import("../views/pages/d-occupation/edit-d-occupation"),
  },

  {
    path: "/d-exterior-color",
    name: "d-exterior-color",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-exterior-color/main-d-exterior-color"),
  },
  {
    path: "/d-exterior-color/form",
    name: "d-exterior-color/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-exterior-color/form-d-exterior-color"),
  },
  {
    path: "/d-exterior-color/edit/:exteriorColorId",
    name: "d-exterior-color/edit",
    meta: {
      authRequired: true,
    },

    component: () =>
      import("../views/pages/d-exterior-color/edit-d-exterior-color"),
  },

  {
    path: "/d-interior-color",
    name: "d-interior-color",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-interior-color/main-d-interior-color"),
  },
  {
    path: "/d-interior-color/form",
    name: "d-interior-color/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-interior-color/form-d-interior-color"),
  },
  {
    path: "/d-interior-color/edit/:interiorColorId",
    name: "d-interior-color/edit",
    meta: {
      authRequired: true,
    },

    component: () =>
      import("../views/pages/d-interior-color/edit-d-interior-color"),
  },

  {
    path: "/d-product-sell",
    name: "d-product-sell",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-product-sell/main-d-product-sell"),
  },
  {
    path: "/d-product-sell/form",
    name: "d-product-sell/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-product-sell/form-d-product-sell"),
  },
  {
    path: "/d-product-sell/edit/:qnId",
    name: "d-product-sell/edit",
    meta: {
      authRequired: true,
    },

    component: () =>
      import("../views/pages/d-product-sell/edit-d-product-sell"),
  },
  {
    path: "/contact-job/add-contactJob",
    name: "add-contactJob",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/add-contactJob"),
  },
  {
    path: "/contact-job/add-contactCalendar",
    name: "add-contactCalendar",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/add-contactJob"),
  },
  {
    path: "/contact-job/edit-contactJob/:ctJobId",
    name: "edit-contactJob",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/edit-contactJob"),
  },
  {
    path: "/contact-job/edit-contactCalendar/:ctJobId",
    name: "edit-contactCalendar",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/edit-contactJob"),
  },
  // {
  //   path: '/contact-job/add-contactCalendar',
  //   name: 'add-contactCalendar',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/customer/add-contactCalendar')
  // },
  // {
  //   path: '/contact-job/edit-contactCalendar/:ctJobId',
  //   name: 'edit-contactCalendar',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/customer/edit-contactCalendar')
  // },
  {
    path: "/receive-docCus",
    name: "receiveDocCus",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/receiveDocCus"),
  },
  {
    path: "/receive-docCus/add-receiveDocCus",
    name: "add-receiveDocCus",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/add-receiveDocCus"),
  },
  {
    path: "/receive-docCus/edit-receiveDocCus/:regJobId",
    name: "receiveDocCus-edit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/edit-receiveDocCus"),
  },
  {
    path: "/deliver-doc",
    name: "deliverDoc",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/deliverDoc"),
  },
  {
    path: "/deliver-doc/add-deliverDoc",
    name: "add-deliverDoc",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/add-deliverDoc"),
  },
  {
    path: "/deliver-doc/edit-deliverDoc/:regJobId",
    name: "edit-deliverDoc",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/edit-deliverDoc"),
  },
  {
    path: "/receiveDoc-branch",
    name: "receiveDoc-branch",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/receiveDocBranch"),
  },
  {
    path: "/receiveDoc-branch/edit-receiveDocBranch/:regJobId",
    name: "edit-receiveDocBranch",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/registration/edit-receiveDocBranch"),
  },

  {
    path: "/send-docReg",
    name: "sendDocReg",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/sendDocReg"),
  },
  {
    path: "/send-docReg/edit-sendDocReg/:regJobId",
    name: "edit-sendDocReg",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/edit-sendDocReg"),
  },
  {
    path: "/receive-docReg",
    name: "receiveDocReg",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/receiveDocReg"),
  },
  {
    path: "/receive-docReg/edit-receiveDocReg/:regJobId",
    name: "edit-receiveDocReg",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/edit-receiveDocReg"),
  },

  {
    path: "/vehicle-booking",
    name: "vehicle-booking",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/vehicle-booking/main-vehicle-booking"),
  },
  {
    path: "/vehicle-booking/form",
    name: "vehicle-booking/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/vehicle-booking/form-vehicle-booking"),
  },
  {
    path: "/vehicle-booking/edit/:bookingId",
    name: "vehicle-booking/edit",
    meta: {
      authRequired: true,
    },

    component: () =>
      import("../views/pages/vehicle-booking/edit-vehicle-booking"),
  },
  {
    path: "/send-docBranch",
    name: "sendDocBranch",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/sendDocBranch"),
  },
  {
    path: "/send-docBranch/edit-sendDocBranch/:regJobId",
    name: "edit-sendDocBranch",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/edit-sendDocBranch"),
  },
  {
    path: "/branch-receiveDoc",
    name: "branchReceiveDoc",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/branchReceiveDoc"),
  },
  {
    path: "/branch-receiveDoc/edit-branchReceiveDoc/:regJobId",
    name: "edit-branchReceiveDoc",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/registration/edit-branchReceiveDoc"),
  },
  {
    path: "/send-docCus",
    name: "sendDocCus",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/sendDocCus"),
  },
  {
    path: "/send-docCus/edit-sendDocCus/:regJobId",
    name: "edit-sendDocCus",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/edit-sendDocCus"),
  },

  {
    path: "/vehicle-sale",
    name: "vehicle-sale",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/vehicle-sale/main-vehicle-sale"),
  },
  {
    path: "/vehicle-sale/form",
    name: "vehicle-sale/form",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/vehicle-sale/form-vehicle-sale"),
  },
  {
    path: "/vehicle-sale/edit/:saleId",
    name: "vehicle-sale/edit",
    meta: {
      authRequired: true,
    },

    component: () => import("../views/pages/vehicle-sale/edit-vehicle-sale"),
  },

  {
    path: "/vehicle-apv",
    name: "vehicle-apv",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/vehicle-apv/main-vehicle-apv"),
  },
  {
    path: "/form-vehicle-apv/:saleId",
    name: "form-vehicle-apv",
    meta: {
      authRequired: true,
    },

    component: () => import("../views/pages/vehicle-apv/form-vehicle-apv"),
  },

  {
    path: "/document-type",
    name: "document-type",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-document-type/main-d-document-type"),
  },
  {
    path: "/d-document-type/form",
    name: "d-document-type/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-document-type/form-d-document-type"),
  },
  {
    path: "/d-document-type/edit/:docTypeId",
    name: "d-document-type/edit",
    meta: {
      authRequired: true,
    },

    component: () =>
      import("../views/pages/d-document-type/edit-d-document-type"),
  },

  {
    path: "/d-usability",
    name: "d-usability",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/d-usability/main-d-usability"),
  },
  {
    path: "/d-usability/form",
    name: "d-usability/form",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/d-usability/form-d-usability"),
  },
  {
    path: "/d-usability/edit/:useId",
    name: "d-usability/edit",
    meta: {
      authRequired: true,
    },

    component: () => import("../views/pages/d-usability/edit-d-usability"),
  },

  {
    path: "/d-person",
    name: "d-person",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/d-person/main-d-person"),
  },
  {
    path: "/d-person/form",
    name: "d-person/form",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/d-person/form-d-person"),
  },
  {
    path: "/d-person/edit/:personId",
    name: "d-person/edit",
    meta: {
      authRequired: true,
    },

    component: () => import("../views/pages/d-person/edit-d-person"),
  },

  {
    path: "/d-vehicle-model",
    name: "d-vehicle-model",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-vehicle-model/main-d-vehicle-model"),
  },
  {
    path: "/d-vehicle-model/form",
    name: "d-vehicle-model/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-vehicle-model/form-d-vehicle-model"),
  },
  {
    path: "/d-vehicle-model/edit/:modelId",
    name: "d-vehicle-model/edit",
    meta: {
      authRequired: true,
    },

    component: () =>
      import("../views/pages/d-vehicle-model/edit-d-vehicle-model"),
  },

  {
    path: "/chat",
    name: "fb-chat",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/facebookchat/chat"),
  },
  // {
  //   path: '/crmLine',
  //   name: 'crmLine',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/crmLine/crmLine'),
  // },
  {
    path: "/reg-check-status",
    name: "reg-check-status",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/reg-check-status"),
  },
  {
    path: "/reg-check-status/detail-regCheckStatus/:regJobId",
    name: "detail-regCheckStatus",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/registration/detail-regCheckStatus"),
  },
  {
    path: "/not-send-reg-doc",
    name: "not-send-reg-doc",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/registration/not-send-reg-doc"),
  },
  {
    path: "/travel-expenses",
    name: "travel-expenses",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/travel-expenses/main-travel-expenses"),
  },
  {
    path: "/travel-expenses/form",
    name: "travel-expenses-form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/travel-expenses/form-travel-expenses"),
  },
  {
    path: "/travel-expenses/:trvexpId",
    name: "travel-expenses-edit",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/travel-expenses/edit-travel-expenses"),
  },
  {
    path: "/branch-approval",
    name: "main-branch-approval",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/approval/main-branch-approval"),
  },
  {
    path: "/branch-approval/detail-branch-approval/:formId",
    name: "detail-branch-approval",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/approval/detail-branch-approval"),
  },
  {
    path: "/dealer-approval",
    name: "main-dealer-approval",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/approval/main-dealer-approval"),
  },
  {
    path: "/dealer-approval/detail-dealer-approval/:formId",
    name: "detail-dealer-approval",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/approval/detail-dealer-approval"),
  },
  {
    path: "/sale-analytic",
    name: "sale-analytic",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/data-analysis/sale-analytic"),
  },
  {
    path: "/liff",
    name: "liff",
    component: () => import("../views/pages/line-liff/liff"),
  },
  {
    path: "/dealer-import-sales",
    name: "dealer-import-sales",
    component: () => import("../views/pages/importData/dealer-import-sales"),
  },
  {
    path: "/dealer-import-sales/show/:notiId",
    name: "dealer-import-sales-show",
    component: () =>
      import("../views/pages/importData/dealer-import-sales-show"),
  },
  {
    path: "/dealer-import-sales-all",
    name: "dealer-import-sales-all",
    component: () =>
      import("../views/pages/importData/dealer-import-sales-all"),
  },
  {
    path: "/sale-contact-job",
    name: "sale-contact-job",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/sale/contactJob"),
  },
  {
    path: "/sale-contact-job/add-contactJob",
    name: "add-sale-contactJob",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/sale/add-contactJob"),
  },
  {
    path: "/sale-contact-job/edit-contactJob/:ctJobId",
    name: "edit-sale-contactJob",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/sale/edit-contactJob"),
  },
  {
    path: "/sale-contact-job/contactJob-calendar",
    name: "contactJob-calendar",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/sale/contactJobCalendar"),
  },
  {
    path: "/sale-contact-job/add-contactCalendar",
    name: "add-sale-contactCalendar",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/sale/add-contactCalendar"),
  },
  {
    path: "/sale-contact-job/edit-contactCalendar/:ctJobId",
    name: "edit-sale-contactCalendar",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/sale/edit-contactCalendar"),
  },

  {
    path: "/line-connect",
    name: "line-connect",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/line-connect/main-line-connect"),
  },
  {
    path: "/line-connect/form/:lineId",
    name: "line-connect-form",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/line-connect/form-line-connect"),
  },
  {
    path: "/sales-target",
    name: "sales-target",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/sale/sales-target"),
  },
  {
    path: "/sales-target/add-salesTarget",
    name: "add-salesTarget",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/sale/add-salesTarget"),
  },
  {
    path: "/sales-target/edit-salesTarget/:targetId",
    name: "edit-salesTarget",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/sale/edit-salesTarget"),
  },
  {
    path: "/dealer-audit-subject",
    name: "dealer-audit-subject",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/audit/dealer-audit-subject"),
  },
  {
    path: "/dealer-audit-subject/add-suditSubject",
    name: "add-suditSubject",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/audit/add-suditSubject"),
  },
  {
    path: "/dealer-audit-subject/edit-suditSubject/:subjectId",
    name: "edit-suditSubject",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/audit/edit-suditSubject"),
  },
  {
    path: "/branch-audit-sale",
    name: "branch-audit-sale",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/audit/branch-audit-sale"),
  },
  {
    path: "/branch-audit-sale/add-auditSale",
    name: "add-auditSale",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/audit/add-auditSale"),
  },
  {
    path: "/branch-audit-sale/edit-auditSale/:auditId",
    name: "edit-auditSale",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/audit/edit-auditSale"),
  },
  {
    path: "/branch-approve-auditSale",
    name: "branch-approve-auditSale",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/audit/branch-approve-auditSale"),
  },
  {
    path: "/branch-approve-auditSale/edit-approve-auditSale/:auditId",
    name: "edit-approve-auditSale",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/audit/edit-approve-auditSale"),
  },
  {
    path: "/branch-test-drive",
    name: "branchTestDrive",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/branchTestDrive"),
  },
  {
    path: "/branch-test-drive/add-branchTestDrive",
    name: "add-branchTestDrive",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/add-branchTestDrive"),
  },
  {
    path: "/branch-test-drive/edit-branchTestDrive/:testId",
    name: "edit-branchTestDrive",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/edit-branchTestDrive"),
  },
  {
    path: "/ppccNotify",
    name: "ppccNotify",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/ppccNotify"),
  },
  {
    path: "/ccNotify",
    name: "ccNotify",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/sale/ccNotify"),
  },
  {
    path: "/dealer-import-ppcus",
    name: "dealer-import-ppcus",
    component: () => import("../views/pages/importData/dealer-import-ppcus"),
  },
  {
    path: "/loanApplication",
    name: "loanApplication",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/finance/loanApplication"),
  },
  {
    path: "/loanApplication/create-loan-application",
    name: "create-loan",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/finance/createLoanApplication"),
  },
  {
    path: "/loanApplication/detail-loan-application/:loanId",
    name: "detail-loan",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/finance/detailLoanApplication"),
  },
  {
    path: "/followLoanApplication",
    name: "followLoanApplication",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/follow-loan-application/followLoanApplication"),
  },
  {
    path: "/followLoanApplication/detail-follow-loan-application/:loanId",
    name: "detail-follow-loan",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "../views/pages/follow-loan-application/detailFollowLoanApplication"
      ),
  },
  {
    path: "/team",
    name: "team",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/sale/team"),
  },
  {
    path: "/team/add-team",
    name: "add-team",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/sale/add-team"),
  },
  {
    path: "/team/edit-team/:teamId",
    name: "edit-team",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/sale/edit-team"),
  },
  {
    path: "/dealer-import-booking",
    name: "dealer-import-booking",
    component: () => import("../views/pages/importData/dealer-import-booking"),
  },
  {
    path: "/kpi-team",
    name: "kpi-team",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/data-analysis/kpi-team"),
  },
  {
    path: "/market-scan",
    name: "market-scan",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/market-scan"),
  },
  {
    path: "/market-scan/add-marketScan",
    name: "add-marketScan",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/add-marketScan"),
  },
  {
    path: "/market-scan/edit-marketScan/:mkScanId",
    name: "edit-marketScan",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/edit-marketScan"),
  },
  {
    path: "/social-media-setting",
    name: "social-media-setting",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/socialMediaSetting/socialMediaSetting"),
  },
  {
    path: "/detail-profile",
    name: "detail-profile",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/users/update-profile"),
  },
  {
    path: "/public-pp-cus-form",
    name: "public-pp-cus-form",
    meta: {
      authRequired: false,
    },
    component: () => import("../views/pages/public/pp-cus-form"),
  },
  {
    path: "/public-mk-scan-form",
    name: "public-mk-scan-form",
    meta: {
      authRequired: false,
    },
    component: () => import("../views/pages/public/mk-scan-form"),
  },


  {
    path: "/d-event",
    name: "d-event",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-event/main-d-event"),
  },
  {
    path: "/d-event/form",
    name: "d-event/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-event/form-d-event"),
  },
  {
    path: "/d-event/edit/:eventId",
    name: "d-event/edit",
    meta: {
      authRequired: true,
    },

    component: () =>
      import("../views/pages/d-event/edit-d-event"),
  },


  {
    path: "/d-customer-source-info",
    name: "d-customer-source-info",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-customer-source-info/main-d-customer-source-info"),
  },
  {
    path: "/d-customer-source-info/form",
    name: "d-customer-source-info/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-customer-source-info/form-d-customer-source-info"),
  },
  {
    path: "/d-customer-source-info/edit/:srcInfoId",
    name: "d-customer-source-info/edit",
    meta: {
      authRequired: true,
    },

    component: () =>
      import("../views/pages/d-customer-source-info/edit-d-customer-source-info"),
  },


  {
    path: "/d-salary",
    name: "d-salary",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-salary/main-d-salary"),
  },
  {
    path: "/d-salary/form",
    name: "d-salary/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-salary/form-d-salary"),
  },
  {
    path: "/d-salary/edit/:salaryId",
    name: "d-salary/edit",
    meta: {
      authRequired: true,
    },

    component: () =>
      import("../views/pages/d-salary/edit-d-salary"),
  },


  {
    path: "/buyer-objective",
    name: "buyer-objective",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/buyer-objective/main-buyer-objective"),
  },
  {
    path: "/buyer-objective/form",
    name: "buyer-objective/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/buyer-objective/form-buyer-objective"),
  },
  {
    path: "/buyer-objective/edit/:objId",
    name: "buyer-objective/edit",
    meta: {
      authRequired: true,
    },

    component: () =>
      import("../views/pages/buyer-objective/edit-buyer-objective"),
  },


  {
    path: "/vehicle-sub-type",
    name: "vehicle-sub-type",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/vehicle-sub-type/main-vehicle-sub-type"),
  },
  {
    path: "/vehicle-sub-type/form",
    name: "vehicle-sub-type/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/vehicle-sub-type/form-vehicle-sub-type"),
  },
  {
    path: "/vehicle-sub-type/edit/:subTypeId",
    name: "vehicle-sub-type/edit",
    meta: {
      authRequired: true,
    },

    component: () =>
      import("../views/pages/vehicle-sub-type/edit-vehicle-sub-type"),
  },



  {
    path: "/vehicle-type",
    name: "vehicle-type",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/vehicle-type/main-vehicle-type"),
  },
  {
    path: "/vehicle-type/form",
    name: "vehicle-type/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/vehicle-type/form-vehicle-type"),
  },
  {
    path: "/vehicle-type/edit/:typeId",
    name: "vehicle-type/edit",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/vehicle-type/edit-vehicle-type"),
  },




  {
    path: "/lifestyle",
    name: "lifestyle",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/lifestyle/main-lifestyle"),
  },
  {
    path: "/lifestyle/form",
    name: "lifestyle/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/lifestyle/form-lifestyle"),
  },
  {
    path: "/lifestyle/edit/:lifestyleId",
    name: "lifestyle/edit",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/lifestyle/edit-lifestyle"),
  },



  {
    path: "/d-other-vehicle-model",
    name: "d-other-vehicle-model",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-other-vehicle-model/main-d-other-vehicle-model"),
  },
  {
    path: "/d-other-vehicle-model/form",
    name: "d-other-vehicle-model/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-other-vehicle-model/form-d-other-vehicle-model"),
  },
  {
    path: "/d-other-vehicle-model/edit/:modelId",
    name: "d-other-vehicle-model/edit",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-other-vehicle-model/edit-d-other-vehicle-model"),
  },





  {
    path: "/branch-supplier",
    name: "branch-supplier",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/branch-supplier/main-branch-supplier"),
  },
  {
    path: "/branch-supplier/form",
    name: "branch-supplier/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/branch-supplier/form-branch-supplier"),
  },
  {
    path: "/branch-supplier/edit/:supId",
    name: "branch-supplier/edit",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/branch-supplier/edit-branch-supplier"),
  },




  {
    path: "/branch-stock",
    name: "branch-stock",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/branch-stock/main-branch-stock"),
  },
  {
    path: "/branch-stock/form",
    name: "branch-stock/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/branch-stock/form-branch-stock"),
  },
  {
    path: "/branch-stock/edit/:stockId",
    name: "branch-stock/edit",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/branch-stock/edit-branch-stock"),
  },




  {
    path: "/branch-parking-slot",
    name: "branch-parking-slot",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/branch-parking-slot/main-branch-parking-slot"),
  },
  {
    path: "/branch-parking-slot/form",
    name: "branch-parking-slot/form",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/branch-parking-slot/form-branch-parking-slot"),
  },
  {
    path: "/branch-parking-slot/edit/:pslotId",
    name: "branch-parking-slot/edit",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/branch-parking-slot/edit-branch-parking-slot"),
  },




  {
    path: "/market-scan-analytics",
    name: "market-scan-analytics",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/market-scan-analytics/market-scan-analytics"),
  },
  {
    path: "/crm-analytics",
    name: "crm-analytics",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/crm-analytics/crm-analytics"),
  },

  {
    path: "/branch-contact",
    name: "branch-contact",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/branch-contact/branchContact"),
  },
  {
    path: "/messge-success",
    name: "messge-success",
    meta: {
      authRequired: false,
    },
    component: () => import("../views/pages/public/messge-success"),
  },
  {
    path: "/application-tel",
    name: "application-tel",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/application-tel/application-tel"),
  },
  {
    path: "/d-factor",
    name: "main-d-factor",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-factor/main-d-factor"),
  },
  {
    path: "/d-factor/form-d-factor",
    name: "form-d-factor",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-factor/form-d-factor"),
  },
  {
    path: "/d-factor/edit-d-factor/:factorId",
    name: "edit-d-factor",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-factor/edit-d-factor"),
  },
  {
    path: "/d-purpose",
    name: "main-d-purpose",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-purpose/main-d-purpose"),
  },
  {
    path: "/d-purpose/form-d-purpose",
    name: "form-d-purpose",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-purpose/form-d-purpose"),
  },
  {
    path: "/d-purpose/edit-d-purpose/:purposeId",
    name: "edit-d-purpose",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-purpose/edit-d-purpose"),
  },

  {
    path: "/d-event-type",
    name: "main-d-event-type",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-event-type/main-d-event-type"),
  },
  {
    path: "/d-event-type/form-d-event-type",
    name: "form-d-event-type",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-event-type/form-d-event-type"),
  },
  {
    path: "/d-event-type/edit-d-event-type/:eventTypeId",
    name: "edit-d-event-type",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/d-event-type/edit-d-event-type"),
  },
];
