import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'

import vco from "v-click-outside"
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import VueSlideBar from 'vue-slide-bar'
import Vuelidate from 'vuelidate'
import i18n from './i18n'
import store from '@/state/store'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import App from './App.vue'
import underscore from 'vue-underscore';
// As a plugin
import VueMask from 'v-mask'
Vue.config.productionTip = false
Vue.use(underscore);
import * as VueGoogleMaps from "vue2-google-maps";
import Lightbox from 'vue-easy-lightbox'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import GmapCluster from 'vue2-google-maps/dist/components/cluster' 
import InfiniteLoading from 'vue-infinite-loading';
import VueQr from 'vue-qr'
Vue.component('GmapCluster', GmapCluster)
Vue.component('vue-qr', VueQr)


Vue.use( CKEditor );
Vue.use(Lightbox)
Vue.use(InfiniteLoading, { /* options */ });
Vue.use(VueGoogleMaps, {
  load: {
    // key: "AIzaSyBJKStkkIpjK2_fOy33qPGPGjWc0fEw4s0",//http://localhost/
    key: "AIzaSyC4zjYLqYFQEnFOGlLaVzsRuueg4qlIMvE",//dms
    libraries: "places"
  },
  installComponents: true
});

import { initFirebaseBackend } from './helpers/firebase/authUtils';
// import VueFbCustomerChat from 'vue-fb-customer-chat'

// Vue.use(VueFbCustomerChat, {
//   page_id: 107320020619190, //  change 'null' to your Facebook Page ID,
//   theme_color: '#333333', // theme color in HEX
//   locale: 'en_US', // default 'en_US'
// })

//import { configureBackend } from './helpers/fakebackend/fake-backend';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
  //configureBackend();
}

import '@/assets/scss/app.scss'
 
// Vue.component('QrcodeVue', QrcodeVue)
Vue.component('VueSlideBar', VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);

Vue.use(require("vue-chartist"));
Vue.use(VueMask);

Vue.prototype.$overlayVariant = "primary";
Vue.prototype.$overlayType = "grow";
Vue.prototype.$overlayRounded = "sm";

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
